import React, {useEffect, useState} from 'react';
import {Button, Container, Form} from 'react-bootstrap';

const CookiesConsent = () => {
  const [accepted, setAccepted] = useState(false);
  const [checkedRules, setCheckedRules] = useState(false);
  const [checkedData, setCheckedData] = useState(false);

  useEffect(() => {
    const isAccepted = localStorage.getItem('cookiesAccepted');
    if (isAccepted) {
      setAccepted(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem('cookiesAccepted', 'true');
    setAccepted(true);
  };

  if (accepted) {
    return null;
  }

  return (
    <div style={{
      position: 'fixed',
      bottom: 0,
      left: 0,
      right: 0,
      backgroundColor: 'rgba(178,178,178,0.9)',
      padding: '20px 0',
      borderTop: '1px solid #ddd',
      zIndex: 1000
    }}>
      <Container>
        <Form>
          <Form.Group className="mb-3">
            <b>
              <Form.Check
                type="checkbox"
                label="Я согласен с правилами использования сайта"
                checked={checkedRules}
                onChange={(e) => setCheckedRules(e.target.checked)}
              />
            </b>
          </Form.Group>

          <Form.Group className="mb-3">
            <b>
              <Form.Check
                type="checkbox"
                label="Я согласен на обработку персональных данных"
                checked={checkedData}
                onChange={(e) => setCheckedData(e.target.checked)}
              />
            </b>
          </Form.Group>

          <Button
            variant="primary"
            onClick={handleAccept}
            disabled={!checkedRules || !checkedData}
          ><b>
            Принять
          </b>
          </Button>
        </Form>
      </Container>
    </div>
  );
};

export default CookiesConsent;