const extensionToMime = {
  // Text-based formats
  'txt': 'text/plain',
  'html': 'text/html',
  'htm': 'text/html',
  'css': 'text/css',
  'js': 'application/javascript',
  'json': 'application/json',
  'xml': 'application/xml',
  'php': 'text/x-php',
  'py': 'text/x-python',
  'md': 'text/markdown',
  'yml': 'text/yaml',
  'yaml': 'text/yaml',
  'ru': 'text/config',

  // Image formats
  'png': 'image/png',
  'jpg': 'image/jpeg',
  'jpeg': 'image/jpeg',
  'gif': 'image/gif',
  'svg': 'image/svg+xml',
  'webp': 'image/webp',

  // Other binary formats
  'pdf': 'application/pdf',
  'zip': 'application/zip',
  'rar': 'application/vnd.rar',
  'doc': 'application/msword',
  'docx': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
};

export const textBasedMimeTypes = new Set([
  'text/plain',
  'text/html',
  'text/css',
  'application/javascript',
  'application/json',
  'application/xml',
  'text/x-php',
  'text/x-python',
  'text/markdown',
  'text/yaml',
  'text/config',
  'application/yaml'
]);

export const getMimeType = (filename) => {
  const cleaned = filename.replace(/^\//, '');
  const extension = cleaned.split('.').pop().toLowerCase();
  return extensionToMime[extension] || 'application/octet-stream';
};