import React, {useState, useEffect} from 'react';
import {Navbar, Container, Nav, Button, Row, Col, InputGroup, Form} from 'react-bootstrap';
import {CodeSlash, ArrowCounterclockwise} from 'react-bootstrap-icons';
import {AgGridReact} from '@ag-grid-community/react';
import {ClientSideRowModelModule} from '@ag-grid-community/client-side-row-model';
import {ModuleRegistry} from '@ag-grid-community/core';
import axios from 'axios';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

ModuleRegistry.registerModules([ClientSideRowModelModule]);

export default function ResultsPage(props) {
  const [hash, setHash] = useState('');
  const [rowData, setRowData] = useState([]);
  const [startDate, setStartDate] = useState(() => {
    const date = new Date();
    date.setDate(date.getDate() - 10);
    return date.toISOString().split('T')[0];
  });
  const [endDate, setEndDate] = useState(new Date().toISOString().split('T')[0]);
  const [columnDefs, setColumnDefs] = useState([]);

  useEffect(() => {
    // Извлекаем hash из URL
    const pathParts = window.location.pathname.split('/');
    const urlHash = pathParts[pathParts.length - 1];
    setHash(urlHash);
  }, []);

  function processData(columns, data) {
    // Создаем набор существующих полей в первом массиве
    let existingFields = new Set(columns.map(col => col.field));

    // Перебираем объекты во втором массиве
    data.forEach(item => {
      // Распарсиваем JSON-строку внутри value
      let parsedValue = JSON.parse(item.value);

      // Перебираем ключи в распарсенном объекте
      Object.keys(parsedValue).forEach(key => {
        // Если поле отсутствует в первом массиве, добавляем его
        if (!existingFields.has(key)) {
          columns.push({field: key, headerName: key});
          existingFields.add(key); // Обновляем набор существующих полей
        }
      });
    });

    // Создаем rowData на основе второго массива
    let rowData = data.map(item => {
      let parsedValue = JSON.parse(item.value);
      let row = {timestamp: item.timestamp};
      Object.keys(parsedValue).forEach(key => {
        row[key] = parsedValue[key];
      });
      return row;
    });

    return rowData;
  }

  useEffect(() => {
    const fetchData = async () => {
      if (!hash) return;

      try {
        const response = await axios.post(`/api/form-results`, {
          start: startDate,
          end: endDate,
          hash: hash
        });
        if (response.data && response.data["status"] === 'ok' && response.data["table_data"]) {
          const newColDef = [{
            field: 'timestamp',
            headerName: 'Время заполнения(GMT+0)',
            width: 250
          }];
          const newRowData = processData(newColDef, response.data["table_data"]);
          setRowData(newRowData);
          setColumnDefs(newColDef);
        }
      } catch (error) {
        console.error('Ошибка загрузки данных:', error);
      }
    };
    fetchData();
  }, [hash, startDate, endDate]);
  console.log(columnDefs, rowData);

  return (
    <>
      {/* Навбар (сохранён из оригинального кода) */}
      <Navbar bg="light" expand="lg" className="py-1"
              style={{
                position: 'sticky',
                top: 0,
                zIndex: 1000,
                transition: 'all 0.3s ease',
                boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
              }}>
        <Container>
          <Navbar.Brand href="#home" className="fw-bold">
            <span style={{
              display: 'inline-block',
              transform: 'rotate(180deg)',
              verticalAlign: 'middle'
            }}>
              <CodeSlash size={28} className="me-2 text-primary"/>
            </span>
            <span style={{display: 'inline-block', verticalAlign: 'top', whiteSpace: 'pre'}}>
              {' CODE '}
            </span>
            <span className="rotating-text" style={{display: 'inline-block', verticalAlign: '-20%'}}>
              наоборот
            </span>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav"/>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <Button variant="outline-dark" className="ms-3" onClick={() => window.location.href = '/ide'}>
                <ArrowCounterclockwise className="me-2" style={{display: 'inline-block', verticalAlign: '-7%'}}/>
                Web-IDE
              </Button>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      {/* Основной контент */}
      <Container className="p-1" style={{height: 'calc(100vh - 80px)'}}>
        <Row className="mb-1 g-3">
          <Col md={3}>
            <InputGroup className="mb-1">
              <InputGroup.Text htmlFor="startDate">С:</InputGroup.Text>
              <Form.Control
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                max={endDate}
              />
            </InputGroup>
          </Col>
          <Col md={3}>
            <InputGroup className="mb-1">
              <InputGroup.Text htmlFor="endDate">По:</InputGroup.Text>
              <Form.Control
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                min={startDate}
              />
            </InputGroup>
          </Col>
        </Row>

        <div className="ag-theme-alpine" style={{height: 'calc(100% - 20px)', width: '100%'}}>
          <AgGridReact
            columnDefs={columnDefs}
            rowData={rowData}
            defaultColDef={{
              resizable: true,
              sortable: true,
              filter: true,
              flex: 1
            }}
            //pagination={true}
            //paginationPageSize={20}
          />
        </div>
      </Container>
    </>
  );
}