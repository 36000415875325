import {useEffect, useLayoutEffect, useState} from 'react';
import Landing from "./landing";
import Ide from "./ide";
import ResultsPage from "./results";
import CookiesConsent from "./CookiesConsent";

function App() {
  const [route, setRoute] = useState();
  const [code, setCode] = useState('');
  const [sessionId, setSessionId] = useState('');
  const [preview, setPreview] = useState('');
  const [hash, setHash] = useState('');

  useEffect(() => {
    let newURL = '/';
    if (route) {
      switch (route) {
        case 'landing':
          newURL = '/';
          break;
        case 'ide':
          newURL = '/ide';
          break;
        case 'results':
          const pathParts = window.location.pathname.split('/');
          const urlHash = pathParts[pathParts.length - 1];
          newURL = '/results/' + urlHash;
          break;
        default:
          newURL = '/';
      }
      window.history.pushState({}, '', newURL);
    }
  },[route]);

  useLayoutEffect(() => {
          console.log(window.location.pathname, window.location.pathname.split('/'));
    switch (window.location.pathname.split('/')[1]) {
      case 'ide':
        setRoute('ide');
        break;
      case 'results':
        setRoute('results');
        break;
      default:
        setRoute('landing');
    }
  },[]);

  return (
    <>
      {route === 'landing' && <Landing setRoute={setRoute} setCode={setCode} setPreview={setPreview} setSessionId={setSessionId} setHash={setHash}/>}
      {route === 'ide' && <Ide setRoute={setRoute}  code={code} preview={preview} sessionId={sessionId}  setCode={setCode} setPreview={setPreview} setSessionId={setSessionId} setHash={setHash} hash={hash}/>}
      {route === 'results' && <ResultsPage setRoute={setRoute}/>}
      <CookiesConsent />
    </>
  )
}

export default App;