import git from 'isomorphic-git';
import http from 'isomorphic-git/http/web';
import LightningFS from '@isomorphic-git/lightning-fs';
import {Buffer} from 'buffer';
import {getMimeType, textBasedMimeTypes} from "./getMimeType";

window.Buffer = Buffer;
window.fs = new LightningFS('fs');
window.pfs = window.fs.promises;

const corsroxy = 'https://cors.upside-down.ru/https:/';

export const checkRemoteAccess = async (url, authType, username, password, token) => {
  try {
    let branches = await fetchBranches(url, authType, username, password, token);
    //await console.log(branches, url, authType, username, password, token)
    if (branches && branches.length > 0) {
      //console.log('Доступ к удаленному репозиторию разрешен');
      return true;
    }
    return false;
  } catch (error) {
    console.error('Ошибка при проверке доступа к репозиторию:', error);
    return false;
  }
};

export const getAuth = (authType, username, password, token) => () => {
  if (authType === 'Базовая') {
    return {username, password};
  } else if (authType === 'Oauth2: github') {
    return {username: token, password: 'x-oauth-basic'};
  } else if (authType === 'Oauth2: gitlab') {
    return {username: 'oauth2', password: token};
  } else if (authType === 'Oauth2: bitbucket') {
    return {username: 'x-token-auth', password: token};
  }
  return null;
};

export const fetchBranches = async (repoUrl, authType, username, password, token) => {
  try {
    const dir = '/cloned-repo';

    let remoteBranches = [];

    if (!repoUrl) {
      //console.error('Адрес репозитория не указан в настройках');
      return [];
    }
    const refs = await git.listServerRefs({
      fs: window.fs,
      http,
      dir: '/',
      url: repoUrl,
      corsProxy: corsroxy,
      onAuth: getAuth(authType, username, password, token),
    });
    //console.log(refs)
    remoteBranches = refs
      .filter(ref => ref.ref.startsWith('refs/heads/'))
      .map(ref => ref.ref.replace('refs/heads/', ''));

    const filteredBranches = remoteBranches.filter(branch => branch !== 'HEAD');
    return filteredBranches;
  } catch (error) {
    console.error('Ошибка при получении веток:', error);
    return [];
  }
};

const createDirectoryRecursive = async (dirPath) => {
  const parts = dirPath.split('/').filter(part => part !== '');
  let currentPath = '';

  for (const part of parts) {
    currentPath = currentPath ? `${currentPath}/${part}` : `/${part}`;
    try {
      await window.pfs.stat(currentPath);
    } catch (error) {
      if (error.code === 'ENOENT') {
        await window.pfs.mkdir(currentPath);
      } else {
        throw error;
      }
    }
  }
};

export const addRemoteRepo = async (repoUrl) => {
  const dir = '/cloned-repo';
// Проверяем, настроен ли remote
  let remoteExists = false;
  try {
    const remotes = await git.listRemotes({
      fs: window.fs,
      dir,
    });

    // Если список remotes не пустой, значит remote настроен
    if (remotes.length > 0) {
      remoteExists = true;
      //console.log('Удаленный репозиторий настроен:', remotes);
    } else {
      //console.log('Удаленный репозиторий не настроен.');
    }
  } catch (error) {
    console.log('Ошибка при проверке remote:', error);
    remoteExists = false;
  }

  // Если remote не настроен, добавляем его
  if (!remoteExists) {
    //console.log('Добавляем удаленный репозиторий...');
    await git.addRemote({
      fs: window.fs,
      dir,
      remote: 'origin',
      url: repoUrl,
    });
    //console.log('Удаленный репозиторий успешно добавлен');
  }
}

export const pushChanges = async (branch, force, authType, username, password, token, files, commitMessage, isNewBranch = false, repoUrl) => {
  try {
    //console.log('Начало отправки изменений...');
    const dir = '/cloned-repo';

    // Проверяем, настроен ли remote
    addRemoteRepo(repoUrl);

    // Проверяем, существует ли ветка локально
    const localBranches = await git.listBranches({
      fs: window.fs,
      dir,
    });

    // Если выбрана новая ветка, создаем её
    if (isNewBranch) {
      //console.log('Создание новой ветки:', branch);
      await git.branch({
        fs: window.fs,
        dir,
        ref: branch,
        checkout: true, // Переключаемся на новую ветку
      });
    } else if (!localBranches.includes(branch)) {
      //console.log('Создание ветки на основе удаленной:', branch);
      await git.branch({
        fs: window.fs,
        dir,
        ref: branch,
        checkout: true, // Переключаемся на новую ветку
      });
    }

    // Обновляем локальные файлы в репозитории
    for (const filePath in files) {
      let content = files[filePath];
      if (/^data:\w+\/\w+;base64,/.test(content)) {
        console.log(content)
        let base64String = content.split(',')[1];
        content = Buffer.from(base64String, 'base64');
      }

      const fullPath = `${dir}${filePath}`;

      // Создаем директории, если они не существуют
      const dirPath = fullPath.substring(0, fullPath.lastIndexOf('/'));
      //console.log('Проверка директории:', dirPath);
      try {
        await window.pfs.stat(dirPath);
        //console.log('Директория существует:', dirPath);
      } catch (error) {
        if (error.code === 'ENOENT') {
          //console.log('Директория не существует, создание:', dirPath);
          await createDirectoryRecursive(dirPath);
        } else {
          throw error;
        }
      }

      // Записываем содержимое файла
      //console.log('Запись файла:', fullPath);
      await window.pfs.writeFile(fullPath, content, 'utf8');
    }

    // Добавляем все изменения в индекс
    //console.log('Добавление изменений в индекс...');
    await git.add({
      fs: window.fs,
      dir,
      filepath: '.',
    });

    // Создаем коммит с переданным сообщением
    //console.log('Создание коммита...');
    //console.log('message ', commitMessage);
    await git.commit({
      fs: window.fs,
      dir,
      author: {
        name: 'Your Name',
        email: 'your.email@example.com',
      },
      message: commitMessage,
    });

    // Отправляем изменения на удалённый сервер
    //console.log('Отправка изменений на удаленный сервер...');
    await git.push({
      fs: window.fs,
      http,
      dir,
      remote: 'origin',
      ref: branch,
      corsProxy: corsroxy,
      force,
      onAuth: getAuth(authType, username, password, token),
    });

    //console.log('Изменения успешно отправлены');
  } catch (error) {
    console.error('Ошибка при отправке изменений:', error);

    // Обработка ошибки "Push rejected because it was not a simple fast-forward"
    if (error.message.includes('Push rejected because it was not a simple fast-forward')) {
      throw new Error('Отправка отклонена, так как это не является простым fast-forward. Используйте "force: true", чтобы перезаписать изменения.');
    }

    // Пробрасываем ошибку для обработки в вызывающем коде
    throw error;
  }
};

export const cloneRepository = async (url, authType, username, password, token, ref, onProgress) => {
  try {
    const dir = '/cloned-repo';

    try {
      const stats = await window.pfs.stat(dir);
      if (stats.isDirectory()) {
        await recursiveRemove(dir);
      }
    } catch (error) {
    }

    await window.pfs.mkdir(dir);

    await git.clone({
      fs: window.fs,
      http,
      dir,
      url,
      singleBranch: true,
      corsProxy: corsroxy,
      onAuth: getAuth(authType, username, password, token),
      onProgress,
      ref
    });
    //console.log('Репозиторий успешно клонирован');
    const files = await scanRepository(dir);
    return files;
  } catch (error) {
    console.error('Ошибка при клонировании репозитория:', error);
    throw error;
  }
};

const recursiveRemove = async (path) => {
  const files = await window.pfs.readdir(path);
  for (const file of files) {
    const filePath = `${path}/${file}`;
    const stats = await window.pfs.stat(filePath);
    if (stats.isDirectory()) {
      await recursiveRemove(filePath);
    } else {
      await window.pfs.unlink(filePath);
    }
  }
  await window.pfs.rmdir(path);
};

export const scanRepository = async (dir) => {
  const files = {};
  function arrayBufferToBase64(buffer) {
    let binary = '';
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
    }
    return btoa(binary);
  }
  await git.walk({
    fs: window.fs, dir, trees: [git.TREE({ref: 'HEAD'})], map: async (filename, [head]) => {
      if (await head.type() !== 'tree') {
        if (filename !== '.') {
          try {
            const mimeType = getMimeType(filename);
            let content;
            const isText = mimeType.startsWith('text/') || textBasedMimeTypes.has(mimeType);
            if (isText) {
                // Чтение текстового файла
                content = await window.pfs.readFile(`${dir}/${filename}`, 'utf8');
            } else {
                // Чтение бинарного файла и кодирование в base64
                const buffer = await window.pfs.readFile(`${dir}/${filename}`);
                const base64String = arrayBufferToBase64(buffer);
                content = `data:${mimeType};base64,${base64String}`;
            }
            files[`/${filename}`] = content;
            //console.log(`/${filename}`,content)
          } catch (error) {
            console.error(`Ошибка при чтении файла ${dir}/${filename}:`, error);
          }
        }
      }
    }, reduce: async (parent, children) => {
      return parent === undefined ? children.flat() : [parent, children].flat();
    }, iterate: async (walk, children) => {
      return Promise.all([...children].map(walk));
    }
  });
  return files;
};