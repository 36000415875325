import { useState, useEffect, useRef } from 'react';

const VoiceInputForm = ({onTextSubmit}) => {
  const [text, setText] = useState('');
  const [isListening, setIsListening] = useState(false);

  const startVoiceInput = () => {
    if ('SpeechRecognition' in window || 'webkitSpeechRecognition' in window) {
      const recognition = new (window.SpeechRecognition || window.webkitSpeechRecognition)();
      recognition.lang = 'ru-RU';

      recognition.onresult = (event) => {
        const transcript = event.results[0][0].transcript;
        setText(prev => prev + ' ' + transcript);
        setIsListening(false);
      };

      recognition.onerror = () => {
        setIsListening(false);
      };

      recognition.start();
      setIsListening(true);
    }
  };

  const toggleVoiceInput = () => {
    startVoiceInput();
    setIsListening(!isListening);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (text.trim()) {
      onTextSubmit(text);
      setText('');
    }
  };

  return (
    <div className="h-100 w-100 d-flex flex-column bg-light p-2 text-center">
      <h5>Редактировать сайт:</h5>
      <textarea
        value={text}
        onChange={(e) => setText(e.target.value)}
        placeholder="Введите текст или говорите..."
        className="form-control flex-grow-1 mb-1 fs-5 border-gray-300"
        style={{ resize: 'none' }}
      />

      <div className="d-flex gap-2 align-items-center">
        <button
          type="button"
          onClick={toggleVoiceInput}
          className={`btn ${isListening ? 'btn-danger' : 'btn-secondary'} flex-shrink-0`}
        >
          {isListening ? '⏹ Стоп' : '🎤 Голос'}
        </button>

        <button
          type="submit"
          onClick={handleSubmit}
          disabled={!text.trim()}
          className="btn btn-dark flex-grow-1"
        >
          Отправить
        </button>
      </div>
    </div>
  );
};

export default VoiceInputForm;