import React, {useEffect, useState} from 'react';
import localforage from 'localforage';
import Header from './Header';
import LeftColumn from './LeftColumn';
import Editor from './Editor';
import Preview from './Preview';
import JSZip from 'jszip';
import {saveAs} from 'file-saver';
import VoiceInputForm from "./VoiceInput";
import WaitingForLLMResponse from "./waiting";
import axios from "axios";
import ZipPanel from "./ZipPanel";
import {getMimeType, textBasedMimeTypes} from "./getMimeType";

localforage.config({
  name: 'CodeIDEStorage',
  storeName: 'ide_data'
});

function Ide(props) {
  const [files, setFiles] = useState(null);
  const [sesId, setSesId] = useState('');
  const [previewUrl, setPreviewUrl] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [newFileName, setNewFileName] = useState('');
  const [selectedExtension, setSelectedExtension] = useState('.html');
  const [isGitConnected, setIsGitConnected] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [renewKey, setRenewKey] = useState(0);
  const [isCodeMode, setIsCodeMode] = useState(!props.code);
  const [isLoading, setIsLoading] = useState(true);
  const [taskId, setTaskId] = useState('');
  const [progress, setProgress] = useState(0);
  const [totalProgress, setTotalProgress] = useState(100);
  const [progressText, setProgressText] = useState('Ожидание ответа...');
  const [progressSpeed, setProgressSpeed] = useState(1);
  const [editorLanguage, setEditorLanguage] = useState('');
  const [hash, setHash] = useState(null);

  console.log('hash:', hash, '; sesId:', sesId);

  useEffect(() => {
    if (selectedFile) {
      let ext = selectedFile.split('.')
      ext = ext[ext.length - 1];
      setEditorLanguage(ext === 'js' ? 'javascript' : ext);
    }
  }, [files, selectedFile]);

  useEffect(() => {
    if (files) {
      if (!files[selectedFile]) {
        if (Object.keys(files).length > 0) {
          if (Object.keys(files)[0] !== "/.env.upside-down.ru") {
            setSelectedFile(Object.keys(files)[0]);
          } else {
            if (Object.keys(files)[1]) {
              setSelectedFile(Object.keys(files)[1]);
            } else {
              setSelectedFile(null);
            }
          }
        } else {
          setSelectedFile(null);
        }
      }
    }

  }, [files]);

  useEffect(() => {
    const initializeData = async () => {
      try {
        const [
          savedFiles,
          savedSession,
          savedPreview,
          savedSelected,
          savedTaskId,
          savedIsCodeMode,
          savedShowModal,
          savedHash,
        ] = await Promise.all([
          localforage.getItem('files'),
          localforage.getItem('sessionId'),
          localforage.getItem('previewUrl'),
          localforage.getItem('selectedFile'),
          localforage.getItem('taskId'),
          localforage.getItem('isCodeMode'),
          localforage.getItem('showModal'),
          localforage.getItem('hash'),
        ]);

        if (props.code) {
          await handlePropCode(props.code);
        } else {
          setFiles(savedFiles || getDefaultFiles());
        }

        /*if (props.hash) {
          await handlePropHash(props.hash);
        } else {
          setHash(savedHash || '');
        }*/

        setSesId(props.sessionId || savedSession || '');
        setPreviewUrl(props.preview || savedPreview || '');
        setHash(props.hash || savedHash || '');
        setSelectedFile(savedSelected || '/index.html');
        setTaskId(savedTaskId || '');
        setIsCodeMode(savedIsCodeMode || false);
        setShowModal(savedShowModal || false);
      } catch (error) {
        console.error('Initialization error:', error);
        setFiles(getDefaultFiles());
      } finally {
        setIsLoading(false);
      }
    };

    initializeData();
  }, []);

  // Сохранение данных при изменениях
  useEffect(() => {
    if (!isLoading) {
      const saveData = async () => {
        try {
          await Promise.all([
            localforage.setItem('files', files),
            localforage.setItem('selectedFile', selectedFile),
            localforage.setItem('sessionId', sesId),
            localforage.setItem('previewUrl', previewUrl),
            localforage.setItem('taskId', taskId),
            localforage.setItem('isCodeMode', isCodeMode),
            localforage.setItem('showModal', showModal),
            localforage.setItem('hash', hash),
          ]);
        } catch (error) {
          console.error('Save error:', error);
        }
      };
      saveData();
    }
  }, [files, selectedFile, sesId, previewUrl, taskId, isCodeMode, showModal, hash]);

  const getDefaultFiles = () => ({
    '/index.html': '<!DOCTYPE html>\n<html>\n<head>\n<meta charset="UTF-8">\n<title>Страница по умолчанию</title>\n</head>\n<body>\n<h1>Привет, Мир!</h1>\n</body>\n</html>'
  });

  const handlePropCode = async (code) => {
    try {
      const parsed = JSON.parse(code);
      const transformed = Object.keys(parsed).reduce((acc, key) => {
        acc[`/${key}`] = parsed[key];
        return acc;
      }, {});

      await localforage.setItem('files', transformed);
      setFiles(transformed);
    } catch (error) {
      console.error('Error processing prop code:', error);
      setFiles(getDefaultFiles());
    }
  };

  /*const handlePropHash = async (hash) => {
    try {
      await localforage.setItem('hash', hash);
      setHash(hash);
    } catch (error) {
      console.error('Error processing prop hash:', error);
      setHash('');
    }
  };*/

  const handleApiResponse = async (responseData) => {
    try {
      const parsedCode = JSON.parse(responseData.code);
      const newFiles = Object.keys(parsedCode).reduce((acc, key) => {
        acc[`/${key}`] = parsedCode[key];
        return acc;
      }, {});
      console.log(responseData)
      await Promise.all([
        localforage.setItem('files', newFiles),
        localforage.setItem('sessionId', responseData.id),
        localforage.setItem('previewUrl', responseData.preview),
        localforage.setItem('hash', responseData.hash)
      ]);

      setFiles(newFiles);
      setSesId(responseData.id);
      setPreviewUrl(responseData.preview);
      setHash(responseData.hash);
    } catch (error) {
      console.error('Error handling API response:', error);
    }
  };

  const handleTextSubmit = async (text) => {
    if (!text.trim()) return;

    try {
      setShowModal(true);
      const codeArray = Object.entries(files).map(([path, content]) => ({
        file_name: path.slice(1),
        code: content
      }));
      const response = await axios.post('/api/gen-task', {
        "id": sesId,
        "mode": "edit",
        "code": codeArray,
        "prompt": text,
        "hash": hash,
      });

      setTaskId(response.data.task_id); // Запускаем опрос через эффект

    } catch (error) {
      setShowModal(false);
      console.error('Ошибка генерации:', error);
    }
  };

  useEffect(() => {
    console.log('taskId:', taskId);
    if (!taskId) return;

    let isMounted = true;
    const controller = new AbortController();

    const pollTask = async () => {
      try {
        const response = await axios.get(`/api/gen-task/${taskId}`, {
          signal: controller.signal
        });
        const data = response.data;
        console.log('Получена информация о задаче:', data);

        if (isMounted) {
          setProgress(data.progress || 0);
          setTotalProgress(data.total || 100);
          setProgressText(data.message || 'Обработка запроса...');
          setProgressSpeed(data.speed || 0);
        }

        if (data.status === 'generated') {
          handleApiResponse(data);
          props.setCode(data.code);
          props.setSessionId(data.id);
          props.setPreview(data.preview);
          setShowModal(false);
          props.setRoute('ide');
          setTaskId('');
          setRenewKey(prev => prev + 1);
        } else {
          setTimeout(pollTask, 5000);
        }
      } catch (error) {
        if (isMounted && error.name !== 'CanceledError') {
          console.error('Ошибка опроса задачи:', error);
          // Продолжаем опрос через 5 секунд после ошибки
          setTimeout(pollTask, 5000);
        }
      }
    };

    setTimeout(() => {
      pollTask();
    }, 1000);


    return () => {
      isMounted = false;
      controller.abort();
    };
  }, [taskId]);

  const handleCancel = async () => {
    if (taskId) {
      await fetch(`/api/stop-gen-task/${taskId}`, {method: 'GET'});
      setTaskId();
    }
  };

  const handleCreateFile = () => {
    if (newFileName && !files[`/${newFileName}${selectedExtension}`]) {
      const newFiles = {...files, [`/${newFileName}${selectedExtension}`]: ''};
      setFiles(newFiles);
      setSelectedFile(`/${newFileName}${selectedExtension}`);
      setNewFileName('');
      setSelectedExtension('.html');
    }
  };

  const handleFileSelect = (fileName) => {
    setSelectedFile(fileName);
  };

  const handleEditorChange = (value) => {
    if (selectedFile) {
      setFiles(prev => ({...prev, [selectedFile]: value}));
    }
  };

  const handleDeleteFile = (fileName) => {
    if (fileName !== '/index.html') {
      const newFiles = {...files};
      delete newFiles[fileName];
      setFiles(newFiles);
      if (selectedFile === fileName) {
        setSelectedFile(Object.keys(newFiles)[0] || null);
      }
    }
  };

  const handleFileUpload = (file) => {
    if (!file) return;

    const reader = new FileReader();

    reader.onload = async (event) => {
      const content = event.target.result;

      if (file.name.endsWith('.zip')) {
        setFiles({});

        const zip = await JSZip.loadAsync(content);
        const newFiles = {};

        zip.forEach((relativePath, zipEntry) => {
          if (!zipEntry.dir) {
            newFiles[`/${relativePath}`] = '';
          }
        });

        for (const relativePath in newFiles) {
          try {
            const fileEntry = zip.file(relativePath.substring(1));
            if (fileEntry) {
              const mimeType = getMimeType(relativePath);

              const isText = mimeType.startsWith('text/') || textBasedMimeTypes.has(mimeType);
              if (isText) {
                newFiles[relativePath] = await fileEntry.async('string');
              } else {
                const base64Content = await fileEntry.async('base64');
                newFiles[relativePath] = `data:${mimeType};base64,${base64Content}`;
              }
            } else {
              console.error(`Файл ${relativePath} не найден в архиве.`);
            }
          } catch (error) {
            console.error(`Ошибка при чтении файла ${relativePath}:`, error);
          }
        }

        console.log('newFiles', newFiles);
        const hash = newFiles['/.env.upside-down.ru'];
        console.log('newFiles hash:', hash);
        setHash(hash || '');

        setFiles(newFiles);
        if (Object.keys(newFiles).length > 0) {
          if (Object.keys(newFiles)[0] !== '/.env.upside-down.ru') {
            setSelectedFile(Object.keys(newFiles)[0]);
          } else {
            if (Object.keys(newFiles)[1]) {
              setSelectedFile(Object.keys(newFiles)[1]);
            } else {
              setSelectedFile(null);
            }
          }
        } else {
          setFiles({});

          const fileName = `/${file.name}`;
          setFiles({[fileName]: content});
          setSelectedFile(fileName);
        }
      }


      reader.readAsArrayBuffer(file);
    }
  }

  const onClickRenew = async () => {
    try {
      setShowModal(true);
      const codeArray = Object.entries(files).map(([path, content]) => ({
        file_name: path.slice(1),
        code: content
      }));
      const response = await axios.post('/api/gen-task', {
        "id": sesId,
        "mode": "deploy",
        "code": codeArray,
        "prompt": "renew",
        "hash": hash,
      });

      setTaskId(response.data.task_id); // Запускаем опрос через эффект

    } catch (error) {
      setShowModal(false);
      console.error('Ошибка генерации:', error);
    }
  }

  const handleFileDownload = () => {
    const zip = new JSZip();

    const prepareContent = (content) => {
      // Для Data URL с Base64 (например: изображения)
      if (/^data:\w+\/\w+;base64,/.test(content)) {
        //console.log('content:', content);
        const cleaned = content
          .replace(/^data:\w+\/\w+;base64,/, '') // Удаляем префикс
          .padEnd(
            content.length + (4 - (content.length % 4)) % 4,
            '='
          );

        return {
          data: cleaned,
          options: {base64: true} // Включаем бинарный режим
        };
      }

      // Для обычного текста
      return {
        data: content,
        options: {binary: false} // Явно указываем текстовый режим
      };
    };

    Object.keys(files).forEach((fileName) => {
      const content = files[fileName];
      const parts = fileName.split('/');
      let currentFolder = zip;

      parts.forEach((part, index) => {
        if (index === parts.length - 1) {
          try {
            const {data, options} = prepareContent(content);
            currentFolder.file(part, data, options);
          } catch (error) {
            console.error(`Файл ${fileName}:`, error);
            // Добавляем как текст в случае ошибки
            currentFolder.file(part, content);
          }
        } else {
          if (!currentFolder.folder(part)) {
            currentFolder = currentFolder.folder(part);
          } else {
            currentFolder = currentFolder.folder(part);
          }
        }
      });
    });

    zip.generateAsync({type: 'blob'}).then((content) => {
      saveAs(content, 'project.zip');
    });
  };

  const handleFilesUpdate = (newFiles) => {
    console.log('newFiles', newFiles);
    const hash = newFiles['/.env.upside-down.ru'];
    console.log('newFiles hash:', hash);
    setHash(hash || '');
    setSesId('');
    setPreviewUrl('');
    setTaskId('');
    setFiles(newFiles);
    if (Object.keys(newFiles).length > 0) {
      if (Object.keys(newFiles)[0] !== '/.env.upside-down.ru') {
        setSelectedFile(Object.keys(newFiles)[0]);
      } else {
        if (Object.keys(newFiles)[1]) {
          setSelectedFile(Object.keys(newFiles)[1]);
        } else {
          setSelectedFile(null);
        }
      }
    }
  }

  const handleGitConnected = () => {
    setIsGitConnected(true);
  };

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center align-items-center vh-100">
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Загрузка...</span>
        </div>
      </div>
    );
  }


  return (
    <>
      <Header
        setRoute={props.setRoute}
        isCodeMode={isCodeMode}
        setIsCodeMode={setIsCodeMode}
      />
      <div className="d-flex flex-column bg-light">

        <div className={(isCodeMode ? "flex-fill" : "container border") + " p-0 pb-1"} style={{height: 'calc(100vh - 56px)'}}>
          <div className="row h-100 m-0">
            <div id="files" className={"col bg-light p-0 col-12 col-xxl-2 col-lg-3 " + (isCodeMode ? "" : " d-none")}>
              <LeftColumn
                files={files}
                selectedFile={selectedFile}
                onFileSelect={handleFileSelect}
                newFileName={newFileName}
                onNewFileNameChange={setNewFileName}
                onCreateFile={handleCreateFile}
                onDeleteFile={handleDeleteFile}
                selectedExtension={selectedExtension}
                onSelectedExtensionChange={setSelectedExtension}
                onFileUpload={handleFileUpload}
                onFileDownload={handleFileDownload}
                onFilesUpdate={handleFilesUpdate}
                isGitConnected={isGitConnected}
                onGitConnected={handleGitConnected}
              />
            </div>
            <div className={"col bg-white p-0 col-12 col-xxl-5 col-lg-9 d-flex flex-column " + (isCodeMode ? "" : " d-none")}>
              <div className="current-file-path bg-light p-2 border-bottom">
                {selectedFile || 'Файл не выбран'}
              </div>
              <div id="editor" className="editor-container flex-grow-1" style={{minHeight: '50vh'}}>
                <Editor
                  value={selectedFile ? files[selectedFile] : ''}
                  language={editorLanguage}
                  onChange={handleEditorChange}
                />
              </div>
            </div>
            <div className={"col bg-light px-2 col-12 " + (isCodeMode ? "col-xxl-5 col-sm-12 " : "")}>
              <div id="preview" className="row m-0 p-0 text-center">
                <span style={{fontSize: '24px'}}>Предпросмотр:</span>
                <Preview url={previewUrl} onClickRenew={onClickRenew} renewKey={renewKey} hash={hash}/>
              </div>
              <hr className="m-2"/>
              <div id="chat" className="row m-0">
                <div className={"col col-12" + (isCodeMode ? "" : " col-xxl-8")}>
                  <VoiceInputForm onTextSubmit={handleTextSubmit}/>
                </div>
                <div className={"col col-12" + (isCodeMode ? " d-none " : " col-xxl-4")}>
                  <ZipPanel
                    onFileUpload={handleFileUpload}
                    onFileDownload={handleFileDownload}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <WaitingForLLMResponse
          show={showModal}
          onHide={() => setShowModal(false)}
          currentProgress={progress}
          totalProgress={totalProgress}
          statusText={progressText}
          speed={progressSpeed}
          onCancel={handleCancel}
        />
      </div>
    </>
  );
}

export default Ide;