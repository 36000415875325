import React, { useState } from 'react';
import GitModal from './GitModal';
import { Gear } from 'react-bootstrap-icons';
import FilePanel from './FilePanel';
import ZipPanel from './ZipPanel';

const LeftColumn = ({
  files,
  selectedFile,
  onFileSelect,
  newFileName,
  onNewFileNameChange,
  onCreateFile,
  onDeleteFile,
  selectedExtension,
  onSelectedExtensionChange,
  onFileUpload,
  onFileDownload,
  onFilesUpdate,
  isGitConnected,
  onGitConnected,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMode, setModalMode] = useState('');

  const openModal = (mode) => {
    setModalMode(mode);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="h-100">
      <FilePanel
        files={files}
        selectedFile={selectedFile}
        onFileSelect={onFileSelect}
        newFileName={newFileName}
        onNewFileNameChange={onNewFileNameChange}
        onCreateFile={onCreateFile}
        onDeleteFile={onDeleteFile}
        selectedExtension={selectedExtension}
        onSelectedExtensionChange={onSelectedExtensionChange}
      />

      <div className="row m-0 p-1">
        {isGitConnected ? (
          <>
            <div className="col-3 p-2 text-center">
              <span>Git:</span>
            </div>
            <div className="col-3 p-1">
              <button onClick={() => openModal('Push')} className="btn btn-primary w-100">
                Push
              </button>
            </div>
            <div className="col-3 p-1">
              <button onClick={() => openModal('Git Clone')} className="btn btn-primary w-100">
                Clone
              </button>
            </div>
            <div className="col-3 p-1">
              <button onClick={() => openModal('Add Remote Repository')} className="btn btn-primary w-100">
                <Gear size={20} />
              </button>
            </div>
          </>
        ) : (
          <>
            <div className="col-4 p-2 text-center">
              <span>Git:</span>
            </div>
            <div className="col-4 p-1">
              <button onClick={() => openModal('Git Clone')} className="btn btn-primary w-100">
                Clone
              </button>
            </div>
            <div className="col-4 p-1">
              <button onClick={() => openModal('Add Remote Repository')} className="btn btn-primary w-100">
                <Gear size={20} />
              </button>
            </div>
          </>
        )}
      </div>

      <ZipPanel
        onFileUpload={onFileUpload}
        onFileDownload={onFileDownload}
        onFilesUpdate={onFilesUpdate}
      />

      <GitModal
        isOpen={isModalOpen}
        onBranchSelected={() => {
          setIsModalOpen(true);
        }}
        onRequestClose={closeModal}
        mode={modalMode}
        onFilesUpdate={onFilesUpdate}
        onGitConnected={onGitConnected}
        files={files}
      />
    </div>
  );
};

export default LeftColumn;