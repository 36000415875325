import { useState, useEffect } from 'react';
import {Button, Modal, ProgressBar} from 'react-bootstrap';
import './waiting.css';

const WaitingForLLMResponse = ({
  show,
  onHide,
  currentProgress = 0,
  totalProgress = 100,
  speed = 0.5,
  statusText = '',
  onCancel
}) => {
  const [elapsedTime, setElapsedTime] = useState(0);
  const [nowProgress, setNowProgress] = useState(0);
  //const [nowProgressTotal, setNowProgressTotal] = useState(100);

  useEffect(() => {
    let interval;
    if (show) {
      interval = setInterval(() => {
        setElapsedTime((prev) => prev + 1);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [show]);

  useEffect(() => {
    if (elapsedTime) {
      console.log('totalProgress > nowProgress',totalProgress,nowProgress,totalProgress > nowProgress)
      if (totalProgress > nowProgress) setNowProgress((prev) => (prev + (speed>10?10:speed))>totalProgress?totalProgress:(prev + (speed>10?10:speed)));
    }
  }, [elapsedTime]);

  useEffect(() => {
    if (!show) {
      setElapsedTime(0);
      setNowProgress(0);
    }
  }, [show]);

  useEffect(() => {
    if (currentProgress&&totalProgress) {
      setNowProgress(currentProgress);
    }
  }, [currentProgress, totalProgress]);

  const formatTime = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      centered
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header>
        <Modal.Title>Генерация сайта</Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center">
        <div className="mb-4">
          <ProgressBar
            now={nowProgress}
            max={100}
            label={`${Math.round((nowProgress / 100) * 100)}%`}
            animated
            striped
            variant="primary"
          />
          {statusText && (
            <div className="mt-2 text-muted blinking-text">
              {statusText}
            </div>
          )}
        </div>
        <p>
          Генерация ответа может занимать от 30 секунд до 5 минут.<br />
          Пожалуйста, не закрывайте это окно.
        </p>
        <div className="mt-2">
          <h4>{formatTime(elapsedTime)}</h4>
          <small>Прошло времени</small>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="danger"
          onClick={() => {
            onCancel();
            onHide();
          }}
        >
          Отменить
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default WaitingForLLMResponse;