export function formatString(input) {
    // Заменяем '{' или ';' с последующими пробелами и табуляциями на '{' или ';' с переносом строки
    let result = input.replace(/([{;])\s*/g, '$1\n');

    // Заменяем '}' с предшествующими пробелами и табуляциями на перенос строки и '}'
    result = result.replace(/\s*([}])\s*/g, '\n$1');

    // Убираем лишние переносы строк
    result = result.replace(/\n+/g, '\n');

    return result;
}
