import React, {useEffect, useState} from 'react';
import {Button, Form, FormCheck, Modal, ProgressBar, Spinner} from 'react-bootstrap';
import {
    checkRemoteAccess,
    fetchBranches,
    pushChanges,
    cloneRepository,
    addRemoteRepo,
    scanRepository
} from './GitUtils';

const GitModal = ({isOpen, onRequestClose, mode, onFilesUpdate, onGitConnected, files, onBranchSelected}) => {
    const [repoUrl, setRepoUrl] = useState('');
    const [authType, setAuthType] = useState('Нет');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [token, setToken] = useState('');
    const [progress, setProgress] = useState(0);
    const [phase, setPhase] = useState('');
    const [phaseColor, setPhaseColor] = useState('#a2d2ff');
    const [isCloning, setIsCloning] = useState(false);
    const [branches, setBranches] = useState([]);
    const [selectedBranch, setSelectedBranch] = useState('');
    const [forcePush, setForcePush] = useState(false);
    const [commitMessage, setCommitMessage] = useState('Update files from editor');
    const [isNewBranch, setIsNewBranch] = useState(false);
    const [newBranchName, setNewBranchName] = useState('');
    const [branchError, setBranchError] = useState('');
    const [isLoadingBranches, setIsLoadingBranches] = useState(false);
    const [showBranchSelectionModal, setShowBranchSelectionModal] = useState(false);
    const [clonedBranches, setClonedBranches] = useState([]);
    const [selectedClonedBranch, setSelectedClonedBranch] = useState('');

    useEffect(() => {
        const savedSettings = JSON.parse(localStorage.getItem('gitSettings')) || {};
        setRepoUrl(savedSettings.repoUrl || '');
        setAuthType(savedSettings.authType || 'Нет');
        setUsername(savedSettings.username || '');
        setPassword(savedSettings.password || '');
        setToken(savedSettings.token || '');
    }, []);

    useEffect(() => {
        if (isOpen) {
            localStorage.setItem('gitSettings', JSON.stringify({repoUrl, authType, username, password, token}));
        }
    }, [repoUrl, authType, username, password, token, isOpen]);

    useEffect(() => {
        if ((mode === 'Push' || mode === 'Pull') && isOpen) {
            // Устанавливаем состояние загрузки веток
            setIsLoadingBranches(true);

            fetchBranches(repoUrl, authType, username, password, token)
                .then((branches) => {
                    setBranches(branches);
                    if (branches.length > 0) {
                        setSelectedBranch(branches[0]);
                    }
                })
                .catch((error) => {
                    console.error('Ошибка при загрузке веток:', error);
                })
                .finally(() => {
                    // Завершаем загрузку веток
                    setIsLoadingBranches(false);
                });
        }
    }, [mode, isOpen]);

    const handleRepoUrlChange = (e) => {
        setRepoUrl(e.target.value);
    };

    const handleAuthTypeChange = (e) => {
        setAuthType(e.target.value);
    };

    const handleUsernameChange = (e) => {
        setUsername(e.target.value);
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };

    const handleTokenChange = (e) => {
        setToken(e.target.value);
    };

    const handleBranchChange = (e) => {
        const value = e.target.value;
        if (value === 'new-branch') {
            setIsNewBranch(true); // Выбрана опция "Новая ветка"
            setSelectedBranch(''); // Сбрасываем выбранную ветку
        } else {
            setIsNewBranch(false); // Выбрана существующая ветка
            setSelectedBranch(value); // Устанавливаем выбранную ветку
        }
    };

    const handleForcePushChange = (e) => {
        setForcePush(e.target.checked);
    };

    const handleCommitMessageChange = (e) => {
        setCommitMessage(e.target.value);
    };

    const handleNewBranchNameChange = (e) => {
        const value = e.target.value;
        setNewBranchName(value);
        // Проверка формата названия ветки
        if (!/^[a-zA-Z0-9_-]+$/.test(value)) {
            setBranchError('Название ветки может содержать только буквы, цифры, дефисы и подчеркивания.');
        } else {
            setBranchError('');
        }
    };

    const handleSubmit = async () => {
        if (!repoUrl) {
            alert('Пожалуйста, введите адрес репозитория.');
            return;
        }

        // Если выбрана новая ветка, проверяем название
        if (isNewBranch) {
            if (!newBranchName) {
                alert('Пожалуйста, введите название новой ветки.');
                return;
            }
            if (branchError) {
                alert('Название ветки содержит недопустимые символы.');
                return;
            }
        }

        if (mode === 'Git Clone') {
            try {
                setIsCloning(true);
                await addRemoteRepo(repoUrl);
                // Получаем список веток
                const branches = await fetchBranches(repoUrl, authType, username, password, token);
                console.log(branches)

                // Если веток больше одной, показываем модальное окно выбора ветки
                if (branches.length > 1) {
                    setClonedBranches(branches);
                    setShowBranchSelectionModal(true); // Показываем модальное окно выбора ветки
                } else {
                    if (branches[0]) {
                        cloneBranch(repoUrl, authType, username, password, token, branches[0]);
                    }
                    onRequestClose();
                }
            } finally {
                setIsCloning(false);
            }
        } else if (mode === 'Push') {
            try {
                setIsCloning(true);
                const branchToPush = isNewBranch ? newBranchName : selectedBranch; // Выбираем ветку для пуша
                await pushChanges(branchToPush, forcePush, authType, username, password, token, files, commitMessage, isNewBranch, repoUrl).catch().finally(() => {

                })
            } catch (error) {
                alert(error.message); // Показываем сообщение об ошибке пользователю
            } finally {
                setIsCloning(false);
            }
        } else if (mode === 'Pull') {
            try {
                setIsCloning(true);
                await cloneBranch(repoUrl, authType, username, password, token, selectedBranch);
            } catch (error) {
                alert(error.message); // Показываем сообщение об ошибке пользователю
            } finally {
                setIsCloning(false);
            }
        } else if (mode === 'Add Remote Repository') {
            const accessGranted = await checkRemoteAccess(repoUrl, authType, username, password, token);
            if (!accessGranted) {
                onRequestClose();
                return;
            }
            await addRemoteRepo(repoUrl);
            onGitConnected();
        }
        onRequestClose();
    };

    const getPhaseColor = (phase) => {
        switch (phase) {
            case 'Counting objects':
                return '#a8d5e5';
            case 'Compressing objects':
                return '#f4d35e';
            case 'Receiving objects':
                return '#b5e48c';
            case 'Resolving deltas':
                return '#f0bcd4';
            case 'Analyzing workdir':
                return '#c8d6e5';
            case 'Updating workdir':
                return '#ffccd5';
            default:
                return '#a2d2ff';
        }
    };

    const handleBranchSelection = async () => {
        if (!selectedClonedBranch) {
            alert('Пожалуйста, выберите ветку.');
            return;
        }
        await cloneBranch(repoUrl, authType, username, password, token, selectedClonedBranch);
        onRequestClose();
    };

    const cloneBranch = async (repoUrl, authType, username, password, token, branch) => {
        try {
            setShowBranchSelectionModal(false);
            onBranchSelected();
            setIsCloning(true);
            await cloneRepository(
                repoUrl,
                authType,
                username,
                password,
                token,
                branch,
                (progressEvent) => {
                    setProgress(Math.round((progressEvent.loaded / progressEvent.total) * 100));
                    setPhase(progressEvent.phase);
                    setPhaseColor(getPhaseColor(progressEvent.phase));
                }
            ).then((files) => {
                console.log(files);
                onFilesUpdate(files);
            }).catch((error) => {
                console.error('Ошибка при клонировании репозитория:', error);
            }).finally(() => {
                setIsCloning(false);
                setProgress(0);
                setPhase('');
                setPhaseColor('#a2d2ff');
            });
        } catch (error) {
            console.error('Ошибка при клонировании репозитория:', error);
        } finally {
            setProgress(0);
            setPhase('');
            setPhaseColor('#a2d2ff');
        }
    }

    return (
        <>
            <Modal show={isOpen} onHide={onRequestClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>{mode}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {mode === 'Push' && (<>
                        <Form.Group className="mb-3">
                            <Form.Label>Выберите ветку</Form.Label>
                            {isLoadingBranches ? (
                                <div className="d-flex align-items-center">
                                    <Spinner animation="border" size="sm" role="status" aria-hidden="true"/>
                                    <span className="ms-2">Загрузка веток...</span>
                                </div>
                            ) : (
                                <Form.Select value={isNewBranch ? 'new-branch' : selectedBranch}
                                             onChange={handleBranchChange}>
                                    {branches.map((branch) => (
                                        <option key={branch} value={branch}>
                                            {branch}
                                        </option>
                                    ))}
                                    <option value="new-branch">Новая ветка</option>
                                </Form.Select>
                            )}
                        </Form.Group>

                        {isNewBranch && ( // Поле для ввода названия новой ветки
                            <Form.Group className="mb-3">
                                <Form.Label>Название новой ветки</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={newBranchName}
                                    onChange={handleNewBranchNameChange}
                                    placeholder="Введите название новой ветки"
                                    isInvalid={!!branchError}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {branchError}
                                </Form.Control.Feedback>
                            </Form.Group>
                        )}
                        <Form.Group className="mb-3">
                            <Form.Label>Сообщение коммита</Form.Label>
                            <Form.Control
                                type="text"
                                value={commitMessage}
                                onChange={handleCommitMessageChange}
                                placeholder="Введите сообщение коммита"
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <FormCheck
                                type="checkbox"
                                label="Force push"
                                checked={forcePush}
                                onChange={handleForcePushChange}
                            />
                        </Form.Group>
                    </>)}
                    {mode === 'Pull' && (<>
                        <Form.Group className="mb-3">
                            <Form.Label>Выберите ветку</Form.Label>
                            {isLoadingBranches ? (
                                <div className="d-flex align-items-center">
                                    <Spinner animation="border" size="sm" role="status" aria-hidden="true"/>
                                    <span className="ms-2">Загрузка веток...</span>
                                </div>
                            ) : (
                                <Form.Select value={selectedBranch}
                                             onChange={handleBranchChange}>
                                    {branches.map((branch) => (
                                        <option key={branch} value={branch}>
                                            {branch}
                                        </option>
                                    ))}
                                </Form.Select>
                            )}
                        </Form.Group>
                    </>)}
                    {(mode === 'Git Clone' || mode === 'Add Remote Repository') && (<>
                        <Form.Group className="mb-3">
                            <Form.Label>Адрес репозитория</Form.Label>
                            <Form.Control
                                type="text"
                                value={repoUrl}
                                onChange={handleRepoUrlChange}
                                placeholder="Введите адрес репозитория"
                                required
                                disabled={isCloning}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Авторизация</Form.Label>
                            <Form.Select value={authType} onChange={handleAuthTypeChange} disabled={isCloning}>
                                <option value="Нет">Нет</option>
                                <option value="Базовая">Базовая</option>
                                <option value="Oauth2: github">Oauth2: github</option>
                                <option value="Oauth2: bitbucket">Oauth2: bitbucket</option>
                                <option value="Oauth2: gitlab">Oauth2: gitlab</option>
                            </Form.Select>
                        </Form.Group>

                        {authType === 'Базовая' && (<Form.Group className="mb-3">
                            <Form.Label>Имя пользователя</Form.Label>
                            <Form.Control
                                type="text"
                                value={username}
                                onChange={handleUsernameChange}
                                placeholder="Введите имя пользователя"
                                disabled={isCloning}
                            />
                        </Form.Group>)}

                        {authType === 'Базовая' && (<Form.Group className="mb-3">
                            <Form.Label>Пароль</Form.Label>
                            <Form.Control
                                type="password"
                                value={password}
                                onChange={handlePasswordChange}
                                placeholder="Введите пароль"
                                disabled={isCloning}
                            />
                        </Form.Group>)}

                        {(authType === 'Oauth2: github' || authType === 'Oauth2: bitbucket' || authType === 'Oauth2: gitlab') && (
                            <Form.Group className="mb-3">
                                <Form.Label>Токен</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={token}
                                    onChange={handleTokenChange}
                                    placeholder="Введите токен"
                                    disabled={isCloning}
                                />
                            </Form.Group>)}

                        {progress > 0 && (<div>
                            <ProgressBar>
                                <ProgressBar
                                    animated
                                    now={progress}
                                    label={`${progress}%`}
                                    style={{backgroundColor: phaseColor, transition: 'background-color 0.5s'}}
                                />
                            </ProgressBar>
                            <div style={{marginTop: '5px', fontSize: '0.9rem', color: '#6c757d'}}>
                                Фаза: {phase}
                            </div>
                        </div>)}
                    </>)}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={onRequestClose} disabled={isCloning}>
                        Закрыть
                    </Button>
                    <Button variant="primary" onClick={handleSubmit} disabled={isCloning}>
                        {isCloning ? (<Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        />) : ('Выполнить')}
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Модальное окно для выбора ветки */}
            <Modal show={showBranchSelectionModal} onHide={() => {
            }} backdrop="static" keyboard={false} centered>
                <Modal.Header>
                    <Modal.Title>Выберите ветку</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className="mb-3">
                        <Form.Label>Доступные ветки</Form.Label>
                        <Form.Select value={selectedClonedBranch}
                                     onChange={(e) => setSelectedClonedBranch(e.target.value)}>
                            <option value="">Выберите ветку</option>
                            {clonedBranches.map((branch) => (
                                <option key={branch} value={branch}>
                                    {branch}
                                </option>
                            ))}
                        </Form.Select>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleBranchSelection}>
                        Выбрать
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default GitModal;