import React from 'react';

const FilePanel = ({
  files,
  selectedFile,
  onFileSelect,
  newFileName,
  onNewFileNameChange,
  onCreateFile,
  onDeleteFile,
  selectedExtension,
  onSelectedExtensionChange,
}) => {
  const splitFileName = (fileName) => {
    return fileName.split('/').map((part, index) => (
      <span key={index} className="file-path-part">
        {part}
        {index < fileName.split('/').length - 1 && '/'}
      </span>
    ));
  };

  return (
    <>
      <div className="row">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            onCreateFile();
          }}
          className="mb-1"
        >
          <div className="input-group">
            <div className="input-group-prepend">
              <span className="input-group-text">/</span>
            </div>
            <input
              type="text"
              value={newFileName}
              onChange={(e) => onNewFileNameChange(e.target.value)}
              placeholder="Имя файла"
              className="form-control"
            />
            <select
              className="form-control"
              value={selectedExtension}
              onChange={(e) => onSelectedExtensionChange(e.target.value)}
            >
              <option value=".html">.html</option>
              <option value=".css">.css</option>
              <option value=".js">.js</option>
              <option value=".png">.png</option>
              <option value=".jpeg">.jpeg</option>
            </select>
            <button type="submit" className="btn btn-primary">
              Создать
            </button>
          </div>
        </form>
      </div>
      <div
        className="row p-0 m-0"
        style={
          window.innerWidth > 576
            ? { overflowY: 'auto', height: 'calc(100vh - 230px)' }
            : {overflowY:'hidden', height: 'auto'}
        }
      >
        <ul className="list-group p-0">
          {Object.keys(files).map((fileName) => {
            if (fileName !== '/.env.upside-down.ru') {
              return <li
                key={fileName}
                onClick={() => onFileSelect(fileName)}
                className={
                  selectedFile === fileName
                    ? 'list-group-item active'
                    : 'list-group-item'
                }
                style={{whiteSpace: 'normal'}}
              >
                {splitFileName(fileName)}
                {fileName !== '/index.html' && (
                  <button
                    className="btn btn-danger btn-sm delete-button"
                    onClick={(e) => {
                      e.stopPropagation();
                      onDeleteFile(fileName);
                    }}
                  >
                    ×
                  </button>
                )}
              </li>
            }
            return null
          })}
        </ul>
      </div>
    </>
  );
};

export default FilePanel;