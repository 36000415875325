import React, {useEffect, useState} from 'react';
import {Button, Card, Col, Container, Form, InputGroup, Nav, Navbar, Row} from 'react-bootstrap';
import {ArrowCounterclockwise, Clipboard, CodeSlash, Lightning, Magic, Mic, Send} from 'react-bootstrap-icons';
import axios from "axios";
import WaitingForLLMResponse from "./waiting";

export default function Landing(props) {
  const [input, setInput] = useState('');
  const [isListening, setIsListening] = useState(false);
  const [isValidated, setIsValidated] = useState(false);
  const [formContacts, setFormContacts] = useState({
    name: '',
    email: '',
    message: ''
  });
  const [showModal, setShowModal] = useState(false);
  const [taskId, setTaskId] = useState('');
  const [progress, setProgress] = useState(0);
  const [totalProgress, setTotalProgress] = useState(100);
  const [progressText, setProgressText] = useState('Ожидание ответа');
  const [progressSpeed, setProgressSpeed] = useState(1);

  const startVoiceInput = () => {
    if ('SpeechRecognition' in window || 'webkitSpeechRecognition' in window) {
      const recognition = new (window.SpeechRecognition || window.webkitSpeechRecognition)();
      recognition.lang = 'ru-RU';

      recognition.onresult = (event) => {
        const transcript = event.results[0][0].transcript;
        setInput(prev => prev + ' ' + transcript);
        setIsListening(false);
      };

      recognition.onerror = () => {
        setIsListening(false);
      };

      recognition.start();
      setIsListening(true);
    }
  };

  useEffect(() => {
    console.log('taskId:', taskId);
    if (!taskId) return;

    let isMounted = true;
    const controller = new AbortController();

    const pollTask = async () => {
      try {
        const response = await axios.get(`/api/gen-task/${taskId}`, {
          signal: controller.signal
        });
        const data = response.data;
        console.log('Получена информация о задаче:', data);

        if (isMounted) {
          setProgress(data.progress || 0);
          setTotalProgress(data.total || 1);
          setProgressText(data.message || 'Обработка запроса...');
          setProgressSpeed(data.speed || 1);
        }

        if (data.status === 'generated') {
          props.setHash(data.hash);
          props.setCode(data.code);
          props.setSessionId(data.id);
          props.setPreview(data.preview);
          setShowModal(false);
          props.setRoute('ide');
          setTaskId('');
        } else {
          setTimeout(pollTask, 5000);
        }
      } catch (error) {
        if (isMounted && error.name !== 'CanceledError') {
          console.error('Ошибка опроса задачи:', error);
          // Продолжаем опрос через 5 секунд после ошибки
          setTimeout(pollTask, 5000);
        }
      }
    };

    pollTask();

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, [taskId]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (input === '') return;

    try {
      setShowModal(true);
      const response = await axios.post('/api/gen-task', {
        "id": "",
        "mode": "new",
        "prompt": input,
        "hash": "",
      });

      setInput('');
      setTaskId(response.data.task_id); // Запускаем опрос через эффект
    } catch (error) {
      setShowModal(false);
      console.error('Ошибка генерации:', error);
    }
  };

  const handleCancel = async () => {
    if (taskId) {
      await fetch(`/api/stop-gen-task/${taskId}`, {method: 'GET'});
      setTaskId();
    }
  };

  const handleSubmitContacts = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
      setIsValidated(true);
      return;
    }
    try {
      const response = await axios.post('/api/contacts', formContacts);
      console.log('Response:', response.data);
      setFormContacts({
        name: '',
        email: '',
        message: ''
      })
      setIsValidated(false);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleChangeContacts = (e) => {
    setFormContacts({
      ...formContacts,
      [e.target.name]: e.target.value
    });
  };

  return (
    <>
      <Navbar bg="light" expand="lg" className="py-1"
              style={{
                position: 'sticky',
                top: 0,
                zIndex: 1000,
                transition: 'all 0.3s ease',
                boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
              }}>
        <Container>
          <Navbar.Brand href="#home" className="fw-bold">
            <span style={{
              display: 'inline-block',
              transform: 'rotate(180deg)',
              verticalAlign: 'middle'
            }}>
              <CodeSlash size={28} className="me-2 text-primary"/>
            </span>
            <span style={{display: 'inline-block', verticalAlign: 'top', whiteSpace: 'pre'}}>
              {' CODE '}
            </span>
            <span className="rotating-text" style={{display: 'inline-block', verticalAlign: '-20%'}}>
              наоборот
            </span>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav"/>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <Nav.Link href="#features">Возможности</Nav.Link>
              <Nav.Link href="#contacts">Контакты</Nav.Link>
              <Button variant="outline-dark" className="ms-3" onClick={() => props.setRoute('ide')}>
                <ArrowCounterclockwise className="me-2" style={{display: 'inline-block', verticalAlign: '-7%'}}/>
                Web-IDE
              </Button>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      {/* Форма генерации */}
      <section className="py-5 bg-light">
        <Container className="py-5">
          <Row className="justify-content-center">
            <Col lg={8} className="text-center">
              <h1 className="display-4 fw-bold mb-4">
                Создавайте сайты голосом<br/>и текстовыми запросами
              </h1>
              <Form onSubmit={handleSubmit} className="mb-5">
                <InputGroup className="mb-3 shadow-lg" style={{borderRadius: '15px'}}>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    value={input}
                    onChange={(e) => setInput(e.target.value)}
                    placeholder="Опишите ваш проект или нажмите микрофон..."
                    style={{borderRadius: '15px 0px 0 15px'}}
                  />
                  <Button
                    variant={isListening ? 'danger' : 'outline-secondary'}
                    onClick={startVoiceInput}
                    style={{borderRadius: '0 15px 15px 0'}}
                  >
                    <Mic size={20}/>
                  </Button>
                </InputGroup>

                <Button
                  variant="primary"
                  size="lg"
                  type="submit"
                  className="px-5 fw-bold"
                >
                  <Send className="me-2"/>
                  Сгенерировать сайт
                </Button>

                <div className="text-muted mt-4">
                  <small>Примеры голосовых команд:</small>
                  <div className="d-flex flex-column gap-2 mt-2">
                    <code onClick={() => setInput("Создай лендинг для автосервиса с тёмной темой и формой записи")}>"Создай лендинг для автосервиса с тёмной
                      темой и формой записи"</code>
                    <code onClick={() => setInput("Нужен интернет-магазин с корзиной и оплатой онлайн")}>"Нужен интернет-магазин с корзиной и оплатой
                      онлайн"</code>
                  </div>
                </div>
              </Form>
            </Col>
          </Row>
        </Container>
      </section>

      {/* Возможности */}
      <section id="features" className="py-5">
        <Container className="py-5">
          <h2 className="text-center mb-5 fw-bold">Наши возможности</h2>
          <Row className="g-4">
            <Col md={4}>
              <Card className="h-100 border-0 shadow-sm">
                <Card.Body className="p-4">
                  <div className="icon-square bg-primary text-white mb-3">
                    <Lightning size={24}/>
                  </div>
                  <h3>Быстрая разработка</h3>
                  <p className="text-muted">
                    Генерируйте компоненты и целые страницы с помощью естественного языка
                  </p>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4}>
              <Card className="h-100 border-0 shadow-sm">
                <Card.Body className="p-4">
                  <div className="icon-square bg-success text-white mb-3">
                    <Magic size={24}/>
                  </div>
                  <h3>Голосовой ввод</h3>
                  <p className="text-muted">
                    Диктуйте требования и мгновенно получайте готовый код
                  </p>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4}>
              <Card className="h-100 border-0 shadow-sm">
                <Card.Body className="p-4">
                  <div className="icon-square bg-info text-white mb-3">
                    <Clipboard size={24}/>
                  </div>
                  <h3>Расширенный IDE</h3>
                  <p className="text-muted">
                    Продвинутая веб-среда разработки с live-превью
                  </p>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>

      {/* Контакты */}
      <section id="contacts" className="py-5 bg-dark text-white">
        <Container className="py-5">
          <h2 className="text-center mb-5 fw-bold">Свяжитесь с нами</h2>
          <Row className="g-5">
            <Col md={6}>
              <Form noValidate validated={isValidated} onSubmit={handleSubmitContacts}>
                <Form.Group className="mb-3" controlId="name">
                  <Form.Label>Ваше имя</Form.Label>
                  <Form.Control required type="text" placeholder="Иван Иванов" name="name" value={formContacts.name} onChange={handleChangeContacts}/>
                  <Form.Control.Feedback type="invalid">Введите ваше имя</Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3" controlId="email">
                  <Form.Label>Email</Form.Label>
                  <Form.Control required type="email" placeholder="example@mail.ru" name="email" value={formContacts.email} onChange={handleChangeContacts}/>
                  <Form.Control.Feedback type="invalid">Введите ваш e-mail адрес</Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-4" controlId="message">
                  <Form.Label>Сообщение</Form.Label>
                  <Form.Control required as="textarea" name="message" rows={4} value={formContacts.message} onChange={handleChangeContacts}/>
                  <Form.Control.Feedback type="invalid">Введите сообщение</Form.Control.Feedback>
                </Form.Group>
                <Button variant="light" type="submit">
                  Отправить сообщение
                </Button>
              </Form>
            </Col>
            <Col md={6} className="d-flex align-items-center">
              <div className="text-center w-100">
                <h4 className="mb-4">Или напишите напрямую</h4>
                <div className="fs-5">
                  <p>support@upside-down.ru</p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <footer className="py-4 bg-light">
        <Container className="text-center text-muted">
          <p className="mb-0">
            © {new Date().getFullYear()} Upside-Down DevTools. Все права защищены.
          </p>
        </Container>
      </footer>
      <WaitingForLLMResponse
        show={showModal}
        onHide={() => setShowModal(false)}
        currentProgress={progress}
        totalProgress={totalProgress}
        statusText={progressText}
        speed={progressSpeed}
        onCancel={handleCancel}
      />
    </>
  );
}