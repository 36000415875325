import React, {useEffect, useRef, useState} from 'react';
import MonacoEditor from '@monaco-editor/react';
import {formatString} from "./formatString";
import {Col, Row} from "react-bootstrap";

const Editor = ({value, onChange, language}) => {
  const editorRef = useRef(null);
  const [showImage, setShowImage] = useState(false);

  const handleEditorDidMount = (editor, monaco) => {
    editorRef.current = editor;
    if (editorRef.current) {
      setTimeout(() => {
        if (language === 'javascript') {
          editorRef.current.setValue(formatString(editorRef.current.getValue()));
        }
        editorRef.current.getAction('editor.action.formatDocument').run();
        editorRef.current.setPosition({lineNumber: 1, column: 1});
      }, 100);

    }
  };

  useEffect(() => {
    if (language === 'png' || language === 'svg' || language === 'jpg' || language === 'jpeg') {
      setShowImage(true);
    } else {
      setShowImage(false);
    }
    if (editorRef.current) {
      setTimeout(() => {
        if (language === 'javascript') {
          editorRef.current.setValue(formatString(editorRef.current.getValue()));
        }
        editorRef.current.getAction('editor.action.formatDocument').run();
        editorRef.current.setPosition({lineNumber: 1, column: 1});
      }, 100);

    }
  }, [language]);

  const handleFileUpload = (file) => {
    if (!file) return;
    const reader = new FileReader();
    reader.onloadend = async () => {
      if (file.name.endsWith('.png') || file.name.endsWith('.jpg') || file.name.endsWith('.jpeg') || file.name.endsWith('.svg')) {
        console.log(reader.result);
        if (editorRef) {
          editorRef.current.setValue(reader.result);
        }
      }
    }
    reader.readAsDataURL(file);
  };

  return (<div className="col bg-white p-0 editor-h">
      {showImage && <>
        <Row>
          <Col>
            <div className="col-4 p-1">
              <input
                type="file"
                accept=".png"
                onChange={(e) => handleFileUpload(e.target.files[0])}
                style={{display: 'none'}}
                id="file-upload-img"
              />
              <label htmlFor="file-upload-img" className="btn btn-primary w-100">
                Заменить изображение
              </label>
            </div>
          </Col>
        </Row>
        <img src={value} className="img-fluid" alt="Responsive image"/>
      </>}
      <MonacoEditor
        height={showImage ? "0px" : "100%"}
        language={language}
        value={value}
        theme={'vs-light'}
        onChange={onChange}
        onMount={handleEditorDidMount}
        options={{
          lineNumbersMinChars: 2,
          lineDecorationsWidth: 20,
          glyphMargin: true,
          renderLineHighlight: 'none',
          wordWrap: 'on',
          formatOnType: true,
          formatOnPaste: true,
          formatOnSave: true,
          autoIndent: "full",
          tabSize: 2,
          automaticLayout: true,
          "editor.formatOnType": true,
          "editor.formatOnPaste": true,
          "editor.semanticHighlighting.enabled": true
        }}
        //baseUri="https://unpkg.com/monaco-editor@0.33.0/min/"
      />
    </div>
  );
};

export default Editor;