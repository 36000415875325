import React from 'react';
import {Container, InputGroup, Nav, Navbar} from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import {CodeSlash} from "react-bootstrap-icons";

const Header = (props) => {
  return (
    <Navbar sticky="top" bg="light" expand="lg" className="py-1"
            style={{
              transition: 'all 0.3s ease',
              boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
            }}>
      <Container fluid>
        <Navbar.Brand href="" onClick={() => props.setRoute('landing')} className="fw-bold">
            <span style={{
              display: 'inline-block',
              transform: 'rotate(180deg)',
              verticalAlign: 'middle'
            }}>
              <CodeSlash size={28} className="me-2 text-primary"/>
            </span>
          <span style={{display: 'inline-block', verticalAlign: 'top', whiteSpace: 'pre'}}>
              {' CODE '}
            </span>
          <span style={{display: 'inline-block', verticalAlign: '-20%', transform: 'rotate(180deg)'}}>
              наоборот
            </span>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav"/>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            {window.innerWidth < 1400 && <>
              {props.isCodeMode && <Nav.Link href="#files">Файлы</Nav.Link>}
              {props.isCodeMode && <Nav.Link href="#editor">Редактор</Nav.Link>}
              <Nav.Link href="#preview">Предпросмотр</Nav.Link>
              <Nav.Link href="#chat">Чат</Nav.Link>
            </>}
            <Nav.Link href="" onClick={() => props.setRoute('landing')}>Главная</Nav.Link>
            {/*<Nav.Link href="#contacts">Документация</Nav.Link>*/}
            {/*<Button variant="outline-dark" className="ms-3" onClick={() => alert('Функционал в разработке!\nБудет доступен через несколько дней.')}>
              <BoxArrowInRight className="me-2" style={{verticalAlign: '-7%'}}/>
              Войти
            </Button>*/}
            <InputGroup size="sm">
              <InputGroup.Text id="inputGroup-sizing-sm">NO CODE</InputGroup.Text>
              <div className="input-group-text">
                <Form.Check // prettier-ignore
                  type="switch"
                  id="custom-switch"
                  checked={props.isCodeMode}
                  onChange={(e) => props.setIsCodeMode(e.target.checked)}
                />
              </div>
              <InputGroup.Text id="inputGroup-sizing-sm">CODE</InputGroup.Text>
            </InputGroup>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;