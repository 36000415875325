import React from 'react';

const ZipPanel = ({onFileUpload, onFileDownload, onFilesUpdate}) => {
  const handleDeleteAllFiles = () => {
    const confirmDelete = window.confirm("Вы уверены, что хотите удалить все файлы?");
    if (confirmDelete) {
      const defaultFiles = {
        '/index.html': '<!DOCTYPE html>\n<html>\n<head>\n<meta charset="UTF-8">\n<title>Страница по умолчанию</title>\n</head>\n<body>\n<h1>Привет, Мир!</h1>\n</body>\n</html>'
      };
      onFilesUpdate(defaultFiles);
    }
  };

  return (
    <div className="row m-0 p-0">
      <div className="d-flex justify-content-between m-0 px-1">
        {!onFilesUpdate && <div className="col-4 p-1 text-center" >
          <span style={{display: "inline-block", verticalAlign: "-20%"}}>
            Файлы проекта:
          </span>
        </div>}
        <div className="col-4 p-1">
          <input
            type="file"
            accept=".zip"
            onChange={(e) => onFileUpload(e.target.files[0])}
            style={{display: 'none'}}
            id="file-upload"
          />
          <label htmlFor="file-upload" className="btn btn-primary w-100">
            Загрузить из zip
          </label>
        </div>
        <div className="col-4 p-1">
          <button onClick={onFileDownload} className="btn btn-primary w-100">
            Выгрузить в zip
          </button>
        </div>
        {onFilesUpdate && <div className="col-4 p-1">
          <button onClick={handleDeleteAllFiles} className="btn btn-danger w-100">
            Удалить все
          </button>
        </div>}
      </div>
    </div>
  );
};

export default ZipPanel;