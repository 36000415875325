import React, {useEffect, useRef, useState} from 'react';
import {Button, Form, InputGroup, OverlayTrigger, Toast, Tooltip} from "react-bootstrap";
import {ArrowClockwise, CodeSlash} from "react-bootstrap-icons";

const Preview = ({url, onClickRenew, renewKey, hash}) => {
  const presets = [
    {label: 'Desktop (1920x1080)', value: 'desktop', width: 1920, height: 1080},
    {label: 'Mobile (375x667)', value: 'mobile', width: 375, height: 667},
    {label: 'Custom', value: 'custom'},
  ];

  const [selectedPreset, setSelectedPreset] = useState('desktop');
  const [width, setWidth] = useState(1920);
  const [height, setHeight] = useState(1080);
  const [scale, setScale] = useState(1);
  const containerRef = useRef(null);
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (selectedPreset !== 'custom') {
      const preset = presets.find(p => p.value === selectedPreset);
      if (preset) {
        setWidth(preset.width);
        setHeight(preset.height);
      }
    }
  }, [selectedPreset]);

  useEffect(() => {
    const updateScale = () => {
      if (containerRef.current) {
        const containerWidth = containerRef.current.offsetWidth;
        const newScale = Math.min(1, containerWidth / width);
        setScale(newScale);
      }
    };

    const observer = new ResizeObserver(updateScale);
    if (containerRef.current) observer.observe(containerRef.current);

    return () => {
      if (containerRef.current) observer.unobserve(containerRef.current);
    };
  }, [width]);

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Временный URL, может быть удален через несколько дней.
      Для сохранения результата выгрузите в ZIP или GIT.
    </Tooltip>
  );
  console.log(hash);
  return (
    <div className="container-fluid text-white p-1">
      {/* URL Display */}
      <div className="row my-1">
        <div className="col">
          <InputGroup className="mb-1">
            <OverlayTrigger
              placement="bottom"
              delay={{show: 250, hide: 400}}
              overlay={renderTooltip}
            >
              <>
                <InputGroup.Text className="d-none d-md-block">Временный URL</InputGroup.Text>
                <InputGroup.Text className="d-md-none">URL</InputGroup.Text>
              </>
            </OverlayTrigger>
            <Form.Control readOnly value={url} onClick={() => {
              if (url) {
                window.navigator.clipboard.writeText(url);
                setShow(true);
              }
            }}/>
            <Button className="btn btn-primary" onClick={() => onClickRenew()}>
              <ArrowClockwise style={{display: 'inline-block', verticalAlign: '-7%'}}/>
            </Button>
            <Toast
              style={{position: 'fixed', right: '20px', zIndex: 9999, borderRadius: 5}}
              onClose={() => setShow(false)}
              show={show}
              delay={3000}
              autohide
            >
              <Toast.Header>
                <CodeSlash size={21} className="me-2 text-primary"/>
                <strong className="me-auto">Upside Down</strong>
              </Toast.Header>
              <Toast.Body><span style={{color: '#000000'}}>Временный URL cкопирован в буфер обмена</span></Toast.Body>
            </Toast>
          </InputGroup>
        </div>
      </div>

      {/* Controls Row */}
      <div className="row mb-3 g-2">
        <div className="col-4 col-md-4">
          <select
            className="form-select"
            value={selectedPreset}
            onChange={(e) => setSelectedPreset(e.target.value)}
          >
            {presets.map((preset) => (
              <option key={preset.value} value={preset.value}>
                {preset.label}
              </option>
            ))}
          </select>
        </div>

        <div className="col-4 col-md-2">
          <input
            type="number"
            className="form-control"
            placeholder="Width"
            value={width}
            onChange={(e) => setWidth(parseInt(e.target.value) || 0)}
            disabled={selectedPreset !== 'custom'}
          />
        </div>

        <div className="col-4 col-md-2">
          <input
            type="number"
            className="form-control"
            placeholder="Height"
            value={height}
            onChange={(e) => setHeight(parseInt(e.target.value) || 0)}
            disabled={selectedPreset !== 'custom'}
          />
        </div>
        <div className="col-12 col-md-4 text-center p-1">
          {hash ? <a href={`/results/${hash}`} target="_blank" className="text-secondary" rel="noreferrer">Ссылка на результаты заполнения формы</a> :
            <p className="text-secondary">На сайте нет формы с результатми</p>}
        </div>
      </div>

      {/* Preview Container */}
      <div className="row">
        <div className="col" style={{height: '63vh', overflow: 'auto'}}>
          <div
            ref={containerRef}
            style={{
              position: 'relative',
              overflow: 'hidden',
              width: '100%',
              height: height * scale,
            }}
          >
            <iframe
              key={renewKey}
              src={url}
              width={`${width}px`}
              height={`${height}px`}
              style={{
                width: `${width}px`,
                height: `${height}px`,
                transform: `scale(${scale})`,
                transformOrigin: '0 0',
                border: 'none',
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Preview;